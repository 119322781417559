import { useContext } from 'react';
import { PaymentContext } from '../providers/paymentContext';
import { calculateTotal, convertCurrency } from '../util';
import useCreditor from './useCreditor';
import useUnpaidInvoices from './useUnpaidInvoices';
import useRates from './useRates';
import { PaymentTimeAdjustmentState } from '../util/constants';

function isObjectAndHasProperties(obj) {
  return typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0;
}

const calculateAdjustmentTotal = (
  invoices,
  adjustmentType,
  targetCurrency,
  rates,
  baseCurrency,
) => {
  let totalCents = 0;
  const invoiceNumbers = [];

  for (const invoice of invoices) {
    let adjustmentCents = 0;
    let isValid = false;

    if (
      adjustmentType === 'fee' &&
      invoice.latePaymentFeesValues?.state === PaymentTimeAdjustmentState.VALID &&
      invoice.latePaymentFeesValues?.lateFeeCents
    ) {
      adjustmentCents = invoice.latePaymentFeesValues.lateFeeCents;
      isValid = true;
    } else if (
      adjustmentType === 'discount' &&
      invoice.earlyPaymentDiscountValues?.state === PaymentTimeAdjustmentState.VALID &&
      invoice.earlyPaymentDiscountValues?.discountCents
    ) {
      adjustmentCents = invoice.earlyPaymentDiscountValues.discountCents;
      isValid = true;
    }

    if (isValid) {
      invoiceNumbers.push(invoice.InvoiceNumber);
      const invCurrency = invoice.CurrencyCode || baseCurrency;
      const convertedCents = convertCurrency(adjustmentCents, invCurrency, targetCurrency, rates);
      totalCents += convertedCents;
    }
  }

  return [Math.ceil(totalCents), invoiceNumbers];
};

function useSelectedTotal(paying = false) {
  const { invoices } = useUnpaidInvoices();
  const { baseCurrency } = useCreditor();
  const {
    paymentOptions: { selectedInvoiceIds, payingInvoiceIds },
  } = useContext(PaymentContext);
  const invoiceIds = paying ? payingInvoiceIds : selectedInvoiceIds;
  const rates = useRates();
  const selectedFullInvoices = invoices ? invoices.filter(i => invoiceIds[i._id] === true) : [];
  const invoicesToAnalyse = invoices ?? [];

  const selectedPartialInvoices = invoicesToAnalyse
    .filter(inv => isObjectAndHasProperties(invoiceIds[inv._id]))
    .map(inv => {
      const { instalments } = inv.paymentPlan;
      const selected = invoiceIds[inv._id];
      const numberOfInstalments = Object.keys(selected).length;
      const amount = instalments
        .filter((_, i) => selected[i])
        .reduce((acc, cur) => acc + cur.amountDue, 0);
      return {
        _id: inv._id,
        InvoiceNumber: inv.InvoiceNumber,
        CurrencyCode: inv.CurrencyCode,
        instalmentsTotal: amount,
        numberOfInstalments,
        isPartial: true,
      };
    });

  const numberOfInstalments = selectedPartialInvoices.reduce(
    (acc, cur) => acc + cur.numberOfInstalments,
    0,
  );

  const combined = [...selectedPartialInvoices, ...selectedFullInvoices];
  const [combinedTotal, combinedCurrency] = calculateTotal(combined, rates, baseCurrency);
  const [fullInvoicesTotal] = calculateTotal(selectedFullInvoices, rates, baseCurrency);

  const invoiceFeesEnabled = combined.reduce(
    (acc, cur) =>
      (acc +=
        (cur.latePaymentFeesValues?.state &&
          cur.latePaymentFeesValues?.state === PaymentTimeAdjustmentState.VALID) ||
        false),
    false,
  );
  const invoiceEarlyPaymentsEnabled = combined.reduce(
    (acc, cur) =>
      (acc +=
        (cur.earlyPaymentDiscountValues?.state &&
          cur.earlyPaymentDiscountValues?.state === PaymentTimeAdjustmentState.VALID) ||
        false),
    false,
  );

  const invoicesWithFees = [];
  const invoicesWithDiscounts = [];

  const targetCurrency = combinedCurrency;

  const [totalFeesCents, feesInvoiceNumbers] = calculateAdjustmentTotal(
    combined,
    'fee',
    targetCurrency,
    rates,
    baseCurrency,
  );

  const [totalDiscountsCents, discountsInvoiceNumbers] = calculateAdjustmentTotal(
    combined,
    'discount',
    targetCurrency,
    rates,
    baseCurrency,
  );

  // Assign invoice numbers to the arrays
  invoicesWithFees.push(...feesInvoiceNumbers);
  invoicesWithDiscounts.push(...discountsInvoiceNumbers);

  const feesAndDiscounts = totalFeesCents / 100 - totalDiscountsCents / 100;

  return {
    invoices: combined,
    numberOfInvoices: combined.length,
    fullInvoices: selectedFullInvoices,
    fullInvoicesAmount: fullInvoicesTotal,
    numberOfInstalments,
    amount: combinedTotal,
    amountWithFeesAndDiscounts: combinedTotal + feesAndDiscounts,
    currency: combinedCurrency,
    totalFeesFromAllInvoices: totalFeesCents / 100,
    totalDiscountsFromAllInvoices: totalDiscountsCents / 100,
    invoiceFeesEnabled,
    invoiceEarlyPaymentsEnabled,
    invoicesWithFees,
    invoicesWithDiscounts,
  };
}

export default useSelectedTotal;
