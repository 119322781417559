import React, { useContext } from 'react';
import {
  Card,
  Text,
  Shelf,
  Box,
  Stack,
  Button,
  Heading,
  useOnMount,
  Tooltip,
  Position,
  Icon,
} from 'chaser-components';
import pluralize from 'pluralize';
import { toCurrency } from '../../../../../util/index';
import useSelectedTotal from '../../../../../hooks/useSelectedTotal';
import { PaymentContext } from '../../../../../providers/paymentContext';
import useBankDetails from '../../../../../hooks/useBankDetails';
import useCardDetails from '../../../../../hooks/useCardDetails';
import { payment, paymentProviders } from '../../../../../util/constants';
import ChaserPayOptionsIcons from '../../../../../assets/ChaserPayOptionsIcons.svg';

const Summary = () => {
  const {
    setPaymentSuccess,
    pay,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);
  const {
    amount: selectedTotal,
    amountWithFeesAndDiscounts,
    currency,
    numberOfInvoices,
    totalFeesFromAllInvoices,
    totalDiscountsFromAllInvoices,
    invoiceFeesEnabled,
    invoiceEarlyPaymentsEnabled,
    invoicesWithFees,
    invoicesWithDiscounts,
  } = useSelectedTotal();
  const bankDetails = useBankDetails();
  const hasBankDetails = Array.isArray(bankDetails) && bankDetails.length > 0;
  const cardDetails = useCardDetails(paymentProviders.unipaas);

  useOnMount(() => {
    if (paymentResult?.success) {
      setPaymentSuccess();
    }
  });

  return (
    <>
      <Card>
        <Box p="medium">
          <Stack dividers>
            <Stack>
              <Text fontWeight="700" fontSize="20px" fontColor="#414141">
                Summary
              </Text>
              <Stack gap="xsmall">
                <Shelf justifyContent="space-between">
                  <Text color="Mid Neutral 1">{pluralize('invoices', numberOfInvoices, true)}</Text>
                  <Text color="Mid Neutral 1">{toCurrency(selectedTotal, currency)}</Text>
                </Shelf>
                {invoiceFeesEnabled && (
                  <Shelf justifyContent="space-between">
                    <Tooltip
                      position={Position.RIGHT}
                      content={`${invoicesWithFees.join(', ')} includes a late payment fee`}
                    >
                      <Shelf gap="xxsmall">
                        <Text color="Mid Neutral 1">Late payment fee</Text>
                        <Icon name="info" size={20} />
                      </Shelf>
                    </Tooltip>
                    <Text color="Mid Neutral 1">
                      {toCurrency(totalFeesFromAllInvoices, currency)}
                    </Text>
                  </Shelf>
                )}
                {invoiceEarlyPaymentsEnabled && (
                  <Shelf justifyContent="space-between">
                    <Tooltip
                      position={Position.RIGHT}
                      content={`${invoicesWithDiscounts.join(
                        ', ',
                      )} includes an early payment discount`}
                    >
                      <Shelf gap="xxsmall">
                        <Text color="Mid Neutral 1">Early payment discount</Text>
                        <Icon name="info" size={20} />
                      </Shelf>
                    </Tooltip>
                    <Text color="Mid Neutral 1">
                      {toCurrency(totalDiscountsFromAllInvoices, currency)}
                    </Text>
                  </Shelf>
                )}
              </Stack>
            </Stack>
            <Stack gap="large">
              <Shelf justifyContent="space-between">
                <Text>Total</Text>
                <Heading data-testid="summary-total" fontWeight="600">
                  {toCurrency(amountWithFeesAndDiscounts, currency)}
                </Heading>
              </Shelf>
              <Stack gap="xsmall" alignItems="stretch" display="flex" flexDirection="column">
                <Tooltip
                  as="span"
                  disable={!cardDetails?.isMultiCurrency}
                  position="left"
                  content="Multi-currency payments are not supported"
                >
                  {cardDetails?.cardPaymentActive && (
                    <>
                      <img
                        width={'161px'}
                        height={'21.48px'}
                        src={ChaserPayOptionsIcons}
                        alt={'Chaser Pay Options'}
                      />
                      <Stack
                        gap="xsmall"
                        alignItems="stretch"
                        display="flex"
                        flexDirection="column"
                      >
                        <Text color="Mid Neutral 1">Select how would you like to pay.</Text>
                        <Text color="Mid Neutral 1">
                          Payment options includes Instant bank transfers, debit cards, credit cards
                          and Apple Pay.
                        </Text>
                        <Button
                          flexGrow="1"
                          as="span"
                          disabled={numberOfInvoices === 0 || cardDetails?.isMultiCurrency}
                          onClick={e => {
                            e.preventDefault();

                            pay({
                              paymentType: payment.card,
                              provider: paymentProviders.unipaas,
                            });
                          }}
                          display="flex"
                          data-testid="pay-card-btn"
                        >
                          Pay now
                        </Button>
                      </Stack>
                    </>
                  )}
                </Tooltip>
                {hasBankDetails && (
                  <Button
                    as="span"
                    disabled={selectedTotal === 0}
                    onClick={() => {
                      pay({
                        paymentType: payment.bank,
                        provider: undefined,
                      });
                    }}
                    display="flex"
                    appearance={'secondary'}
                    data-testid="pay-transfer-btn"
                  >
                    See bank transfer information
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Card>
    </>
  );
};

export default Summary;
